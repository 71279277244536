import React from "react";
import "./banner.css";

export const BodyBanner = ({ banner, bannerlink }) => {
  return (
    <div>
      <figure className="body__banner1-figure">
        <a href={bannerlink} target="_blank">
          <img className="body__banner1-img" src={banner} />
        </a>
      </figure>
    </div>
  );
};

export const SideBanner = ({ banner }) => {
  return (
    <div>
      <figure className="sideBanner__figure">
        <a href={banner.banner1Link} target="_blank">
          <img
            className="sideBanner__img"
            src={banner.banner1}
            style={{ marginBottom: "1.5rem" }}
          />
        </a>
        <a href={banner.banner2Link} target="_blank">
          <img className="sideBanner__img" src={banner.banner2} />
        </a>
      </figure>
    </div>
  );
};
